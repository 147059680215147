<template>
    <div class="schedaStabilimento" style="height:100%;">
        <v-layout justify-center align-center style="height:100%;">
            <v-flex xs12 md10 lg10>
                <v-toolbar color="secondary">
                    <v-toolbar-title class="white--text">
                        Info Stabilimento
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <template v-if="isMaster">
                        <v-btn small icon @click="remove_stabilimento()" color="white">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                        <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                    </template>
                    <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                        <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card outlined>
                    <v-select v-if="stabilimento && stabilimento.reparti && stabilimento.reparti.length"
                              :items="[{_id: '', nome:'Tutti'}].concat(stabilimento.reparti)"
                              v-model="filtroReparto"
                              item-text="nome"
                              item-value="_id"
                              label="Seleziona un reparto"
                              @change="initMap" />
                    <canvas ref="mappa" :style="'width:' + canvasWidth"></canvas>
                    <Schede :id_stabilimento="_id" :noEmitTab="true" :isMaster="isMaster" :actionScheda="true" class="mb-5"/>
                    <v-card-title>
                        Elenco evacuatori
                        <v-btn small icon outlined :to="'/newEvacuatore?id_stabilimento='+_id" color="greyColor" class="ml-3 mr-1" v-if="isMaster">
                            <v-icon small color="primary">fas fa-plus</v-icon>
                        </v-btn>
                        <v-btn small icon outlined @click="showSemestri = !showSemestri" color="greyColor" class="ml-3 mr-1" v-if="ruolo === 'Master'">
                            <v-icon small color="primary">fas fa-list</v-icon>
                        </v-btn>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small icon outlined @click="downloadListaEvacuatori()" color="greyColor" class="ml-3 mr-1" v-bind="attrs" v-on="on">
                                    <v-icon x-small color="primary">fas fa-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Scarica Elenco Evacuatori</span>
                        </v-tooltip>
                    </v-card-title>
                    <ListaEvacuatori :id_stabilimento="_id"
                                     :viewEvacuatore="viewEvacuatore"
                                     :isMaster="isMaster"
                                     :actionScheda="true"
                                     :filterSearch="true"
                                     :showSemestri="showSemestri"
                                     :filtroReparto="filtroReparto"
                                     @closeMenu="viewEvacuatore = undefined" />
                    <v-card-actions>
                        <v-container grid-list-sm fluid>
                            <v-row>
                                <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                                    <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-menu v-model="viewPointMenu"
                bottom
                absolute
                :close-on-click="false"
                :close-on-content-click="false"
                :position-x="positionXpointMenu"
                :position-y="positionYpointMenu"
        >
            <div style="height: 100%; width: 100%; background-color:white;">
                <InfoEvacuatore v-if="pointClicked && pointClicked.id_evacuatore" :id_evacuatore="pointClicked.id_evacuatore" />
                <v-btn v-if="isMaster && pointClicked && !pointClicked.id_evacuatore" color="primary" class="dontClose mx-2 ma-1" x-small @click="cambiaEvacuatore(pointClicked)" fab>
                    <v-icon small class="dontClose">fas fa-exchange-alt</v-icon>
                </v-btn>
                <v-btn v-if="isMaster && pointClicked && !pointClicked.id_evacuatore" color="primary" class="dontClose mx-2 ma-1" x-small @click="addEvacuatore(pointClicked)" fab>
                    <v-icon small class="dontClose">fas fa-plus</v-icon>
                </v-btn>
                <v-btn v-if="pointClicked && pointClicked.id_evacuatore" color="primary" class="dontClose mx-2 ma-1" @click="viewEvacuatore = pointClicked.id_evacuatore" x-small fab>
                    <v-icon small class="dontClose">fas fa-eye</v-icon>
                </v-btn>
                <v-btn v-if="isMaster && pointClicked && pointClicked._id" color="primary" class="dontClose mx-2 ma-1" x-small @click="deletePoint(pointClicked)" fab>
                    <v-icon small class="dontClose">fas fa-trash</v-icon>
                </v-btn>
                <v-btn v-if="isMaster && pointClicked && !pointClicked.id_evacuatore && !pointClicked._id" color="primary" class="dontClose mx-2 ma-1" x-small @click="addPoint(pointClicked)" fab>
                    <v-icon small class="dontClose">fas fa-map-marker</v-icon>
                </v-btn>
            </div>
        </v-menu>
        <v-dialog v-model="isChosingEvacuatore"
                  width="auto"
                  max-width="500"
        >
            <ListaEvacuatori :id_stabilimento="_id" :isChosing="true" @evacuatoreClick="evacuatoreClick"/>
        </v-dialog>
    </div>
</template>

<script>
import apiEvacuatori from '@/js/pages/evacuatori';
import apiStabilimenti from '@/js/pages/stabilimenti';
import { requiredRules } from '@/js/validationRules';
const Schede = () => import('@/components/Schede');
const ListaEvacuatori = () => import('@/components/Lista_evacuatori');
const InfoEvacuatore = () => import('@/components/InfoEvacuatore');
let context;

export default {
    name: 'schedaStabilimento',
    components: {
        Schede,
        ListaEvacuatori,
        InfoEvacuatore
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            ruolo: this.$store.state.ruolo,
            _id: this.$route.query._id,
            isEditing: false,
            indirizzo: '',
            note: '',
            cliente: undefined,
            mappa: undefined,
            canvasWidth: '100%',
            formValid: false,
            requiredRules: requiredRules,
            loading: false,
            documentChange: false,
            lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
            punti: [],
            lista_evacuatori: [],
            viewPointMenu: false,
            positionXpointMenu: 0,
            positionYpointMenu: 0,
            pointClicked: undefined,
            viewEvacuatore: undefined,
            isChosingEvacuatore: false,
            data_punto_change: undefined,
            isMaster: false,
            showSemestri: false,
            stabilimento: undefined,
            filtroReparto: undefined
        }
    },
    async created() {
        if(!this._id) this._id = this.$route.query._id;
        let those = this;
        if(
            this.ruolo &&
            (
                this.ruolo === 'Master' ||
                this.ruolo === 'Tecnico'
            )
        ) this.isMaster = true;
        else this.isMaster = false;
        window.addEventListener('mousedown', async function(e) {
            let dontCloseTarget = false, dontCloseParent = false;
            if(
                e &&
                e.target &&
                e.target.classList &&
                e.target.classList.contains('dontClose')
            ) dontCloseTarget = true;
            if(
                e &&
                e.target &&
                e.target.offsetParent &&
                e.target.offsetParent.classList &&
                e.target.offsetParent.classList.contains('dontClose')
            ) dontCloseParent = true;
            if(
                !dontCloseTarget &&
                !dontCloseParent
            ) {
                if(those.viewPointMenu === true) those.initMap();
                those.viewPointMenu = false;
            }
        });
    },
    async mounted() {
        await this.initMap();
        if(
            this.$route &&
            this.$route.query &&
            this.$route.query.id_e
        ) this.viewEvacuatore = this.$route.query.id_e;
    },
    methods: {
        async initMap() {
            let stabilimento = await apiStabilimenti.getOne(this._id);
            this.punti = stabilimento.punti_mappa || [];
            if(stabilimento.file) {
                let canvas = this.$refs.mappa;
                if(!canvas) {
                    await this.initMap();
                    return;
                }

                let those = this;
                let px_contHeight = canvas.clientHeight;
                let missingHeight = 0;
                let px_contWidth = canvas.clientWidth;
                let offsetWidth = 0;

                let base_image = new Image();
                base_image.src = process.env.VUE_APP_ENDPOINT_API+stabilimento.file;
                base_image.onload = function() {
                    if(px_contWidth > base_image.width) {
                        /*those.canvasWidth = (base_image.width - (px_contWidth - base_image.width) / 2);
                        px_contWidth = those.canvasWidth;*/
                        offsetWidth = 0;
                    }
                    if(px_contHeight < base_image.height) {
                        const tempCount = (base_image.height - px_contHeight);
                        if(tempCount >= 850) missingHeight = 850;
                        else missingHeight = tempCount;
                    }

                    canvas.height = px_contHeight + missingHeight;
                    canvas.width = px_contWidth;

                    context = undefined;
                    context = canvas.getContext('2d');
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    context.imageSmoothingEnabled = false;
                    context.webkitImageSmoothingEnabled = false;
                    context.mozImageSmoothingEnabled = false;

                    context.drawImage(base_image, 0, 0, base_image.width, base_image.height, offsetWidth, 0, (px_contWidth-(offsetWidth*2)), (px_contHeight+missingHeight));

                    for (const punto of those.punti) {
                        let valid = true;
                        if (
                            those.filtroReparto &&
                            String(punto.id_reparto) !== String(those.filtroReparto)
                        ) valid = false;

                        if (valid) {
                            const posXcalc = ((px_contWidth * punto.x) / 100);
                            const posYcalc = (((px_contHeight + missingHeight) * punto.y) / 100);
                            context.beginPath();
                            context.arc(posXcalc, posYcalc, 3, 0, 2 * Math.PI, false);
                            context.lineWidth = 3;
                            context.strokeStyle = punto.colorePunto || '#00ff00';
                            context.stroke();
                        }
                    }
                }

                canvas.addEventListener('mousedown', async function(e) {
                    e.stopPropagation();
                    if(!those.loading){
                        those.loading = true;
                        let objPosition = those.getCursorPosition(canvas, e);
                        const posXcalc = ((objPosition.x * 100) / px_contWidth);
                        const posYcalc = ((objPosition.y * 100) / (px_contHeight+missingHeight));
                        let isOnPunto = those.isOnPunto(posXcalc,posYcalc);
                        those.positionXpointMenu = e.clientX-20;
                        those.positionYpointMenu = e.clientY-50;
                        if(isOnPunto){
                            those.pointClicked = isOnPunto;
                            those.viewPointMenu = true;
                        } else if(those.isMaster) {
                            context.beginPath();
                            context.arc(objPosition.x, objPosition.y, 3, 0, 2 * Math.PI, false);
                            context.lineWidth = 3;
                            context.strokeStyle = '#00ff00';
                            context.stroke();
                            those.pointClicked = {
                                x: posXcalc,
                                y: posYcalc
                            };
                            those.viewPointMenu = true;
                        } else those.viewPointMenu = false;
                        those.loading = false;
                    }
                });
            } else this.$refs.mappa.height = 0;

            this.stabilimento = stabilimento;
        },
        async submit(){
            if(this.isEditing){
                this.loading = true;
                if(
                    this.$refs.form_data.validate() &&
                    this.formValid &&
                    await this.sendFile()
                ){
                    let data = {
                        _id: this._id,
                        indirizzo: this.indirizzo,
                        note: this.note,
                    };
                    await apiStabilimenti.updateOne(this.token, data);
                    this.$router.back();
                }
                this.loading = false;
            } else this.$router.back();
        },
        async remove_stabilimento(){
            if(confirm("Eliminare lo stabilimento?")){
                let data = { _id: this._id };
                await apiStabilimenti.deleteOne(this.token, data);
                this.$router.go(-2);
            }
        },
        async sendFile() {
            if(this.documentChange){
                if(this.documento) {
                    let formData = new FormData();
                    formData.append("documento", this.documento);
                    return await apiStabilimenti.sendFile(this.token, this._id, formData);
                } else return false;
            } else return true;
        },
        getCursorPosition(canvas, event) {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            return {
                x: x,
                y: y
            };
        },
        isOnPunto(x, y) {
            let puntoFound = false;
            for (const punto of this.punti) {
                let calcX = parseInt(punto.x) - parseInt(x);
                if(calcX < 0) calcX = calcX * -1;
                let calcY = parseInt(punto.y) - parseInt(y);
                if(calcY < 0) calcY = calcY * -1;
                if(
                    calcX < 1.2 &&
                    calcY < 1.2
                ) puntoFound = punto;
            }
            return puntoFound;
        },
        async deletePoint(punto) {
            if(confirm('Eliminare il punto nella mappa?')){
                await apiStabilimenti.deletePunto(this.token, { id_stabilimento: this._id, _id: punto._id });
                this.viewPointMenu = false;
                await this.initMap();
            }
        },
        async addEvacuatore(data_punto) {
            let id_punto = await apiStabilimenti.addPunto(this.token, { id_stabilimento: this._id, x: data_punto.x, y: data_punto.y });
            if(id_punto) this.$router.push('/newEvacuatore?id_stabilimento='+this._id+'&id_punto='+id_punto);
        },
        async addPoint(data_punto) {
            let id_punto = await apiStabilimenti.addPunto(this.token, { id_stabilimento: this._id, x: data_punto.x, y: data_punto.y });
            this.viewPointMenu = false;
            await this.initMap();
            if(id_punto) data_punto._id = id_punto;
            return data_punto;
        },
        async cambiaEvacuatore(data_punto) {
            this.isChosingEvacuatore = true;
            if(!data_punto._id) data_punto = await this.addPoint(data_punto);
            this.data_punto_change = data_punto;
            this.viewPointMenu = false;
        },
        async evacuatoreClick(evacuatore) {
            this.isChosingEvacuatore = false;
            await apiEvacuatori.connectPunto(this.token, { id_punto: this.data_punto_change._id, id_evacuatore: evacuatore._id });
            await this.initMap();
            this.data_punto_change = undefined;
        },
        async downloadListaEvacuatori() {
            let nomeFile = await apiEvacuatori.downloadElenco(this.token, { id_stabilimento: this._id, id_reparto: this.filtroReparto });
            if(nomeFile) {
                window.open(process.env.VUE_APP_ENDPOINT_API + '/' + nomeFile, '_BLANK');
            } else {
                alert("Ci sono stati degli errori durante l'esportazione. Riprovare" );
                location.reload();
            }
        }
    }
}
</script>